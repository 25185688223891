import { withPrefix } from "gatsby";
import styled from "styled-components";

interface DocsMainProps {
  isVisible?: boolean;
}

const DocsMain = styled.div<DocsMainProps>`
  height: 100%;
  transition: all 0.3s ease-in-out;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  max-width: 900px;
  img {
    display: flex;
    margin: 0 auto;
    max-width: 80%;
  }
`;

export default DocsMain;
