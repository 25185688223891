import React from "react";
import ReactMarkdown from "react-markdown";
import { withPrefix } from "gatsby";

import StyledTemplateViewHeader from "./Header.styled";
import StyledTemplateViewHeaderInner from "./components/TemplateViewHeaderInner.styled";
import StyledTemplateViewHeaderMain from "./components/TemplateViewHeaderMain.styled";
import StyledTemplateViewHeaderButtons from "./components/TemplateViewHeaderButtons.styled";
import StyledTemplateViewHeaderDeviceThumbnail from "./components/TemplateViewHeaderDeviceThumbnail.styled";

import Button, { ButtonTypes } from "@components/Button";
import { H1, Description } from "@components/Typography";
import Image from "@components/Image";
import HeaderBackgroundIllustration from "@components/HeaderBackgroundIllustration";
import TemplateViewHeaderBackgroundSvg from "@svg/TemplateViewHeaderBackground";

import { TemplateData } from "@gen/gatsby-graphql";
import { getExcerpt } from "@utils/functions";

interface HeaderProps {
  template?: TemplateData;
}

const Header: React.FC<HeaderProps> = ({ template }) => {
  const price = template?.price?.currentPrice;
  const initialPrice = template?.price?.initialPrice;
  return (
    <StyledTemplateViewHeader>
      <StyledTemplateViewHeaderInner>
        <HeaderBackgroundIllustration>
          <TemplateViewHeaderBackgroundSvg
            image={withPrefix(
              template?.thumbnailImages?.thumbnail?.childImageSharp
                ?.fluid?.src,
            )}
          />
        </HeaderBackgroundIllustration>
        <StyledTemplateViewHeaderMain>
          <H1>{template?.name}</H1>
          <Description>
            <ReactMarkdown
              source={getExcerpt(
                template?.description?.shortDescription,
              )}
            />
          </Description>
          <StyledTemplateViewHeaderButtons>
            <a
              href={template?.links?.templateUrl}
              target="_blank"
              title="Buy Now"
            >
              <Button>
                Buy now{" "}
                {initialPrice !== price && (
                  <span>${initialPrice}</span>
                )}{" "}
                ${price}
              </Button>
            </a>
            <a
              href={template?.links?.templateUrl}
              target="_blank"
              title="Preview"
            >
              <Button stylingType={ButtonTypes.Secondary}>
                Preview
              </Button>
            </a>
          </StyledTemplateViewHeaderButtons>
        </StyledTemplateViewHeaderMain>
        <StyledTemplateViewHeaderDeviceThumbnail>
          <Image
            src={template?.thumbnailImages?.imacThumbnail}
            alt={template?.name}
          />
        </StyledTemplateViewHeaderDeviceThumbnail>
      </StyledTemplateViewHeaderInner>
    </StyledTemplateViewHeader>
  );
};

export default Header;
