import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import Tooltip from "rc-tooltip";
import techData from "@data/tech";

import "rc-tooltip/assets/bootstrap.css";

const DetailsTechWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;

const DetailsTechTool = styled.div`
  margin-right: 30px;
  cursor: pointer;
  img {
    max-height: 50px;
  }
`;

interface DetailsTechProps {
  tech?: string[];
  category?: string;
}

const DetailsTech: React.FC<DetailsTechProps> = ({
  tech,
  category,
}) => {
  return (
    <DetailsTechWrapper>
      {tech?.map(item => {
        // @ts-ignore
        const { text, image } = techData[item] || {};

        return (
          <Tooltip overlay={text}>
            <DetailsTechTool>
              <Link
                to={`/templates/search/${category?.toLowerCase()}/${item?.toLowerCase()}`}
                title={text}
              >
                <img src={image} alt={text} title={text} />
              </Link>
            </DetailsTechTool>
          </Tooltip>
        );
      })}
    </DetailsTechWrapper>
  );
};

export default DetailsTech;
