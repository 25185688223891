import styled from "styled-components";
import media from "@styles/media";

const FeaturesRow = styled.div`
  display: flex;
  justify-content: center;
  ${media.toTabletP`
    flex-wrap: wrap;
  `}
`;

export default FeaturesRow;
