import styled from "styled-components";
import media from "@styles/media";

const DetailsDescription = styled.div`
  color: rgb(91 91 91);
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  width: 75%;
  ${media.toLaptopSmall`
    width: 100%;
  `}
`;

export default DetailsDescription;
