import styled from "styled-components";
import media from "@styles/media";

const HeaderBackgroundIllustration = styled.div`
  ${media.toTabletP`
    height: 35vh;
    width: 100%;
    position: static;
    background: transparent
      linear-gradient(to right, #13b0e5 0%, #22259b 100%) 0% 0%
      no-repeat padding-box;
  `}
  svg {
    position: absolute;
    background-repeat: no-repeat;
    top: 0px;
    right: 0px;
    max-width: 70vw;
    max-height: 90vh;
    ${media.toTabletP`
      display: none;
    `}
  }
`;

export default HeaderBackgroundIllustration;
