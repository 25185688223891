import {
  FiBox,
  FiFileText,
  FiServer,
  FiSmartphone,
  FiSettings,
  FiImage,
  FiList,
  FiMonitor,
} from "react-icons/fi";
import { MdWeb, MdPhoneAndroid } from "react-icons/md";
import { GiPirateHook } from "react-icons/gi";
import { FaLanguage } from "react-icons/fa";
import { IoMdCodeWorking } from "react-icons/io";

export default (key?: string, value?: string) => {
  if (key === "COMPONENTS") {
    return {
      key,
      value: value + " Handcrafted Components",
      Icon: FiBox,
    };
  }
  if (key === "DOCUMENTATION") {
    return {
      key,
      value: "Fully documented",
      Icon: FiFileText,
    };
  }
  if (key === "SSR") {
    return {
      key,
      value: "Server-side rendering",
      Icon: FiServer,
    };
  }
  if (key === "PAGES") {
    return {
      key,
      value: value + " Pages",
      Icon: MdWeb,
    };
  }
  if (key === "PWA") {
    return {
      key,
      value: "Progressive Web App",
      Icon: FiSmartphone,
    };
  }
  if (key === "PLUGINS") {
    return {
      key,
      value: value + " Plugins",
      Icon: FiSettings,
    };
  }
  if (key === "LAZY LOADING IMAGES") {
    return {
      key,
      value: "Lazy Loading Images",
      Icon: FiImage,
    };
  }
  if (key === "SEO") {
    return {
      key,
      value: "SEO Ready",
      Icon: FiList,
    };
  }
  if (key === "DESIGN") {
    return {
      key,
      value: "Design files",
      Icon: FiMonitor,
    };
  }
  if (key === "RESPONSIVE") {
    return {
      key,
      value: "Fully Responsive",
      Icon: MdPhoneAndroid,
    };
  }
  if (key === "HOOKS") {
    return {
      key,
      value: "React Hooks",
      Icon: GiPirateHook,
    };
  }
  if (key === "MULTI-LANGUAGE") {
    return {
      key,
      value: "Multi Language support",
      Icon: FaLanguage,
    };
  }
  if (key === "CLEAN-CODE") {
    return {
      key,
      value: "Clean Code",
      Icon: IoMdCodeWorking,
    };
  }
};
