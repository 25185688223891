import styled from "styled-components";
import media from "@styles/media";

const TemplateViewHeader = styled.div`
  width: 100%;
  overflow-x: hidden;
  position: relative;
  height: 100vh;
  ${media.toTabletP`
    height: auto;
    min-height: 100vh;
  `}
`;

export default TemplateViewHeader;
