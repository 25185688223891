import styled from "styled-components";
import media from "@styles/media";
import { H1, Description } from "@components/Typography";

const TemplateViewHeaderMain = styled.div`
  height: 100%;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 100px;
  ${media.toTabletP`
    width: 40%;
  `}
  ${media.toTabletP`
    width: 100%;
    margin-top: -50px;
    background-color: white;
    border-radius: 20px;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  `}
  ${H1} {
    font-size: 54px;
    line-height: 54px;
    ${media.toLaptop`
      font-size: 40px;
      line-height: 42px;
    `}
    ${media.toPhone`
      text-align: center;
      width: auto;
    `}
  }
  ${Description} {
    ${media.toLaptop`
      font-size: 16px;
      line-height: 27px;
    `}
  }
`;

export default TemplateViewHeaderMain;
