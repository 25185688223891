import React from "react";
import styled from "styled-components";

export const TemplateViewFeaturesWrapper = styled.div`
  overflow: hidden;
  max-width: 100vw;
  width: 100%;
  margin-top: 50px;
  svg {
    height: 800px;
    width: 100%;
  }
`;

const TemplateViewFeatures = () => {
  return (
    <TemplateViewFeaturesWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="1920.915"
        height="1184.581"
        viewBox="0 0 1920.915 1184.581"
        preserveAspectRatio="none"
      >
        <g transform="translate(.306 -2202.267)">
          <path
            fill="#0652dd"
            d="M0 0H1920V436H0z"
            transform="translate(0 2549)"
          ></path>
          <path
            fill="#ccdbf8"
            d="M.01 2209.971S77.2 2177.4 134.8 2246.2s76.8 109.6 254 173.6c57.944 20.928 221.6 51.2 416 57.6 403.2 64 1115.218 53.2 1115.218 53.2l-1.818 284.85-1663.8-57.25L3.357 2657.391z"
          ></path>
          <path
            fill="#0652dd"
            d="M-.007 2232.911S77.2 2200.4 134.8 2269.2s76.8 109.6 254 173.6c57.944 20.928 221.6 51.2 416 57.6 403.2 64 1115.381 46.182 1115.381 46.182v301.221L254.4 2781.2l-254.373.213z"
          ></path>
          <path
            fill="#ccdbf8"
            d="M-45.075 2212.556s122.1-35.5 179.621 34.193 76.695 111.026 253.651 175.858c57.865 21.2 221.3 51.865 415.428 58.349 402.645 64.833 1071.455 63.321 1071.455 63.321l.212 286.123-1621.311-64.991L-41.5 2652.943z"
            transform="rotate(180 937.493 2795.182)"
          ></path>
          <path
            fill="#0652dd"
            d="M-45.623 2244.118s122.654-44.125 180.174 25.611 76.694 111.09 253.65 175.961c57.865 21.212 221.3 51.9 415.427 58.383 402.645 64.871 1071.358 53.494 1071.358 53.494v293.956l-1621-62.831-299.058-118.563z"
            transform="rotate(180 937.493 2795.182)"
          ></path>
        </g>
      </svg>
    </TemplateViewFeaturesWrapper>
  );
};

export default TemplateViewFeatures;
