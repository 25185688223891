import styled from "styled-components";
import media from "@styles/media";

interface ParagraphProps {
  center?: string;
}

export const P = styled.p<
  ParagraphProps &
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLParagraphElement>,
      HTMLParagraphElement
    >
>`
  text-align: ${props => (props.center ? "center" : "left")};
`;

export const Description = styled(P)`
  color: #383838;
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;
  font-family: Poppins, sans-serif;
  ${media.toTabletP`
    width: 100%;
    background-color: white;
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  `}
`;
