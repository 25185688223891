import styled from "styled-components";
import media from "@styles/media";

const OverlinedHeader = styled.h2`
  font-size: 3rem;
  color: #2f2f2f;
  width: 200px;
  line-height: 2.8rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
  position: relative;
  ${media.toLaptopMedium`
    font-size: 52px;
    line-height: 50px;
  `}
  ${media.toTabletP`
    font-size: 60px;
    line-height: 50px;
  `}
  ${media.toPhone`
    font-size: 45px;
    line-height: 45px;
  `}
  ${media.toPhoneSmall`
    font-size: 25px;
    line-height: 25px;
  `}
  &::after {
    content: "";
    position: absolute;
    top: -10px;
    height: 6px;
    width: 100px;
    background-color: #1a7ece;
  }
`;

export default OverlinedHeader;
