import styled from "styled-components";
import media from "@styles/media";

const Docs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 70px;
  margin-bottom: 100px;
  margin-top: 70px;
  ${media.toTabletP`
    padding: 0 20px;  
  `}
  > h2 {
    max-width: 900px;
    width: 100%;
  }
`;

export default Docs;
