import styled from "styled-components";
import media from "@styles/media";

export const H1 = styled.h1`
  text-transform: uppercase;
  display: flex;
  align-items: flex-end;
  line-height: 4rem;
  color: #2f2f2f;
  font-size: 70px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 20px 0;
  ${media.toLaptopMedium`
    font-size: 50px;
    line-height: 52px;
  `}
  ${media.toTabletP`
    font-size: 60px;
    line-height: 50px;
    width: 100%;
    font-size: 60px;
    line-height: 70px;
    text-align: center;
    display: flex;
    justify-content: center;
  `}
  ${media.toPhoneLarge`
    font-size: 40px;
    line-height: 45px;
  `}
  ${media.toPhone`
    font-size: 40px;
    line-height: 45px;
  `}
  ${media.toPhoneSmall`
    font-size: 30px;
    line-height: 35px;
  `}
`;
export const H2 = styled.h2`
  font-size: 3rem;
  color: #2f2f2f;
  line-height: 2.8rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 30px;
  position: relative;
`;
