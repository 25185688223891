export default {
  REACT: {
    text: "React",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Freact.png?alt=media&token=f8a78486-20d3-4e93-8b71-d38a22e786ec",
  },
  TAILWIND: {
    text: "Tailwind",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tailwind-css.svg?alt=media&token=ffdf64a5-065b-4165-a14c-757d1e81a7c8",
  },
  NEXTJS: {
    text: "Next.js",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fnextjs.png?alt=media&token=6594c933-3869-4f06-8ea6-b71f68a5c7f4",
  },
  REACTQUERY: {
    text: "React-Query",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/React-Query-logo.webp?alt=media&token=d01bb15d-4e5d-48b8-a6ff-609e6ef7bfdd",
  },
  HTML: {
    text: "HTML",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fhtml.png?alt=media&token=bdaf4a1f-5120-4863-bf06-ff965b0b5cbe",
  },
  CSS: {
    text: "CSS",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fcss.png?alt=media&token=a6c5b7a8-f0dd-46dd-81af-0adef39ba81c",
  },
  SCSS: {
    text: "SCSS",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fscss.png?alt=media&token=514baa8b-35d8-4906-949c-300449e1b551",
  },
  "STYLED-COMPONENTS": {
    text: "Styled Components",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fstyled-components.png?alt=media&token=948874dd-fffd-44e1-9e1e-2ca92490130f",
  },
  ANTD: {
    text: "ANTD - Ant Design",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fantd.png?alt=media&token=e00d2814-5028-42e0-b92a-f6539d5b2e03",
  },
  MATERIAL: {
    text: "Material Ui",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fmaterial-ui.png?alt=media&token=f2d8f331-a8b8-4008-b6f5-17dcfc8dca12",
  },
  REDUX: {
    text: "REDUX",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fredux.png?alt=media&token=033912cf-7431-4809-ac5d-987edadad76e",
  },
  FIREBASE: {
    text: "Firebase",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Ffirebase.png?alt=media&token=6dd2739a-8ecd-4062-888b-ca57a8a9f68e",
  },
  TYPESCRIPT: {
    text: "Typescript",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Ftypescript.svg?alt=media&token=755d0b20-dc09-4e8d-acb3-689c2a6a0920",
  },
  BOOTSTRAP: {
    text: "Bootstrap",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fbootstrap.png?alt=media&token=dd50cca7-e716-4860-ab00-5288f4939aac",
  },
  BULMA: {
    text: "Bulma",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fbulma.png?alt=media&token=add34020-8ab4-4e85-a9eb-968251db0b06",
  },
  FLOW: {
    text: "Flow",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fflow.png?alt=media&token=b091db85-c777-4dad-ab84-1c02e962c894",
  },
  GATSBY: {
    text: "Gatsby",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fgatsby.png?alt=media&token=eba2cdb8-1b7b-4bb0-af8e-29fb74015d4d",
  },
  GRAPHQL: {
    text: "Graphql",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fgraphql.png?alt=media&token=f0048b5e-2d30-4159-a167-0285b0ce4796",
  },
  JEST: {
    text: "Jest",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fjest.png?alt=media&token=3d00d0ce-907e-46e2-9ca4-2c4361baba88",
  },
  NETLIFY: {
    text: "Netlify",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Fnetlify.png?alt=media&token=14c43c7a-beeb-4532-a1f9-cb03e670f14d",
  },
  REACTNATIVE: {
    text: "React Native",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Freact-native.png?alt=media&token=edda8390-d853-4d92-b47e-16b15f273918",
  },
  REACTSTRAP: {
    text: "Reactstrap",
    image:
      "https://firebasestorage.googleapis.com/v0/b/mywebcomponents-official.appspot.com/o/tech%2Freactstrap.png?alt=media&token=4e2690e0-3312-4eba-83cf-39d6d0a98970",
  },
};
