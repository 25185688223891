import React from "react";

import StyledFeaturesContainer from "./Features.styled";
import StyledFeaturesRows from "./components/FeaturesRows.styled";
import StyledFeaturesRow from "./components/FeaturesRow.styled";
import Feature from "./components/Feature";

import TemplateViewFeatures from "@svg/TemplateViewFeatures";
import convertFeature from "@data/features";

interface FeaturesProps {
  features: any;
}

const Features: React.FC<FeaturesProps> = ({ features }) => {
  const firstRow = features.filter(
    (item, key) => key < features.length / 2,
  );

  const secondRow = features.filter(
    (item, key) => key >= features.length / 2,
  );

  return (
    <StyledFeaturesContainer>
      <TemplateViewFeatures />
      <StyledFeaturesRows>
        <StyledFeaturesRow>
          {firstRow.map(({ key, text = "" }) => {
            const feature = convertFeature(key, text);

            return <Feature feature={feature} />;
          })}
        </StyledFeaturesRow>
        <StyledFeaturesRow>
          {secondRow.map(({ key, text = "" }) => {
            const feature = convertFeature(key, text);

            return <Feature feature={feature} />;
          })}
        </StyledFeaturesRow>
      </StyledFeaturesRows>
    </StyledFeaturesContainer>
  );
};

export default Features;
