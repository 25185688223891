import TemplateBox from "@components/TemplateBox";
import React from "react";
import styled from "styled-components";
import media from "@styles/media";

const SuggestionsMain = styled.div`
  height: 100%;
  transition: all 0.3s ease-in-out;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;

  ${media.toTabletP`
    flex-direction: column;
    align-items: center;
  `}
`;

const Container = styled.div`
  max-width: 90vw;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;

  ${media.toTabletP`
    padding: 10px;
  `}
`;

const Suggestions = ({ suggestions = [] }) => {
  return (
    <Container>
      <h2>Suggestions: </h2>

      <SuggestionsMain>
        {suggestions?.slice(0, 3)?.map(templateData => {
          const template = templateData?.node?.data;
          return (
            <TemplateBox
              key={template?.slug}
              description={template?.description}
              name={template?.name}
              price={template?.price}
              slug={template?.slug}
              thumbnailImages={template?.thumbnailImages}
            />
          );
        })}
      </SuggestionsMain>
    </Container>
  );
};

export default Suggestions;
