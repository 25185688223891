import styled from "styled-components";

const Feature = styled.div`
  width: 494px;
  height: 369px;
  border: 1px solid #b9b9b9;
  background-color: #fff;
  border-radius: 20px;
  z-index: 10000;
  margin: 30px 30px;
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default Feature;
