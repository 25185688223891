import styled from "styled-components";
import media from "@styles/media";

const TemplatesViewDetails = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 70px;
  position: relative;
  ${media.toTabletP`
    padding: 0 20px;
    margin-bottom: 100px;
  `}
`;

export default TemplatesViewDetails;
