import styled from "styled-components";
import media from "@styles/media";

const DetailsDesign = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 25vw;
  max-width: 350px;
  background-color: #16a9e1;
  opacity: 0.2;
  height: 120vh;
  z-index: 1;
  border-top-right-radius: 30px;
  ${media.toTabletP`
      display: none;
  `}
  ${media.toTabletP`
      display: none;
  `}
`;

export default DetailsDesign;
