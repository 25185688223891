import styled from "styled-components";
import media from "@styles/media";

const TemplateViewHeaderButtons = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  button {
    span {
      margin: 0 10px;
      text-decoration: line-through;
    }
    ${media.toTabletP`
      width: 100%;
    `}
    &:first-child {
      margin-right: 15px;
      ${media.toTabletP`
        margin-right: 0;
        margin-bottom: 10px;
      `}
    }
  }
  ${media.toTabletP`
    flex-direction: column;
    justify-content: space-between;
  `}
  a {
    text-decoration: none;
    ${media.toTabletP`
      width: 100%;  
    `}
  }
`;

export default TemplateViewHeaderButtons;
