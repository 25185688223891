import styled from "styled-components";

const FeatureHeader = styled.div`
  border-left: 5px solid #1a7ece;
  margin-left: -21px;
  font-size: 22px;
  text-indent: 10px;
  text-transform: uppercase;
`;

export default FeatureHeader;
