import styled from "styled-components";
import media from "@styles/media";

const TemplateViewHeaderDeviceThumbnail = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  /* top: 5%; */
  left: 57%;
  margin: 1em auto;
  perspective: 200px;
  transform: translate(-50%, 10%) rotateY(-10deg);
  transform-style: preserve-3d;
  width: 600px;

  ${media.toLaptopMedium`
    width: 500px;
  `}

  ${media.toTablet`
    width: 400px;
  `}
  ${media.toTabletP`
    top: -5%;
    left: 50%;
    height: 300px;
    width: 300px;
  `}
  ${media.toPhoneLarge`
    top: 50px;
  `}
  ${media.toPhone`
    top: 0;
    height: 200px;
    width: 200px;
  `}
  ${media.toPhoneSmall`
    display: none;
  `}
  .gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }
  img {
    height: 800px;
    width: 100%;
    max-width: 800px;
    max-height: 80vh;
  }
`;

export default TemplateViewHeaderDeviceThumbnail;
