import React from "react";
import ReactMarkdown from "react-markdown";

import DetailsTech from "./components/DetailsTech";
import StyledDetails from "./Details.styled";
import StyledDetailsImage from "./components/DetailsImage.styled";
import StyledDetailsDesign from "./components/DetailsDesign.styled";
import StyledDetailsMain from "./components/DetailsMain.styled";
import StyledDetailsDescription from "./components/DetailsDescription.styled";
import StyledDetailsBackgroundImage from "./components/DetailsBackgroundImage.styled";

import Image from "@components/Image";
import StaticImage from "@components/StaticImage";
import OverlinedHeading from "@components/OverlinedHeading";

interface DetailsProps {
  phoneThumbnail?: string;
  bigDescription?: string;
  name?: string;
  tech?: string[];
  category?: string;
}

const Details: React.FC<DetailsProps> = ({
  phoneThumbnail,
  bigDescription,
  tech,
  name,
  category = "all",
}) => {
  return (
    <StyledDetails>
      <StyledDetailsDesign />
      <StyledDetailsBackgroundImage>
        <StaticImage filename="template-bg-design.png" alt={name} />
      </StyledDetailsBackgroundImage>
      <StyledDetailsImage>
        <Image src={phoneThumbnail} alt={name} />
      </StyledDetailsImage>
      <StyledDetailsMain>
        <OverlinedHeading>Product Description</OverlinedHeading>
        <StyledDetailsDescription>
          <ReactMarkdown source={bigDescription} />
        </StyledDetailsDescription>
        <DetailsTech tech={tech} category={category} />
      </StyledDetailsMain>
    </StyledDetails>
  );
};

export default Details;
