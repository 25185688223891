import React from "react";
import StyledFeature from "./Feature.styled";
import StyledFeatureHeader from "./components/FeatureHeader.styled";
import StyledFeatureIcon from "./components/FeatureIcon.styled";
import StyledFeatureDescription from "./components/FeatureDescription.styled";

interface FeatureProps {}

const Feature: React.FC<FeatureProps> = ({ feature }) => {
  return (
    <StyledFeature>
      <StyledFeatureHeader>{feature.key}</StyledFeatureHeader>
      <StyledFeatureIcon>
        <feature.Icon />
      </StyledFeatureIcon>
      <StyledFeatureDescription>
        {feature.value}
      </StyledFeatureDescription>
    </StyledFeature>
  );
};

export default Feature;
