import styled from "styled-components";
import media from "@styles/media";

const TemplateViewHeaderInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 70px;
  position: relative;
  height: 100%;
  ${media.toTabletP`
    margin: 0;
    padding: 0;
    margin-bottom: 50px;
  `}
`;

export default TemplateViewHeaderInner;
