import styled from "styled-components";

const FeatureIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 7rem;
`;

export default FeatureIcon;
