import styled from "styled-components";
import media from "@styles/media";

const DetailsMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -100px;  
  flex: 3;
  z-index: 10;
  ${media.desktop`
    flex: 4;
  `}
  ${media.toLaptopSmall`
    margin-left: 30px;
  `}
  ${media.toTabletP`
    width: 100%;
    margin-top: -50px;
    background-color: white;
    border-radius: 20px;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  `}
  ${media.toPhone`
    padding: 0;
  `}
`;

export default DetailsMain;
